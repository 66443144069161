.dscp-legal {

	h1,
	h2,
	h3 {
		margin: 48px 0 6px 0;
		color: $navy;
	}

	p {
		margin: 12px 0;
		line-height: 1.5;
	}

	strong {
		color: $navy;
	}
}
