.dscp-footer {

	background: $navy;
	color: $white;

	&-upper {
		display: flex;
		align-items: center;
		justify-content: space-between;
	}

	&-legal-wrapper {
		// width: 50%;
		display: flex;
		justify-content: center;
	}

	&-legal {
		display: flex;
		align-items: stretch;
		margin: 0;
		padding: 10px 0;
		list-style: none;
		text-align: center;
		font-size: 16px;

		li {
			display: flex;
			align-items: center;
			justify-content: center;
			border-right: 1px solid $white;

			&:first-child {
				a {
					padding-left: 10px;
				}
			}

			&:last-child {
				border-right: 0;
			}

		}

		a {
			display: block;
			transform: 0.3s;
			padding: 2px 10px;
			text-decoration: none;
			color: $white;
			font-family: $cera-reg;

			&:hover,
			&:focus {
				transform: 0.3s;
				color: $pale-blue-hover;
			}

		}

	}

	&-logos {

		display: flex;
		align-items: center;
		padding: 10px 0;
		// width: 50%;

		img {
			display: block;
			margin: 10px;
			width: auto;
			height: 20px;
		}

		.logo-xsm {
			height: 15px;
		}

		.logo-sm {
			height: 20px;
		}

		.logo-md {
			height: 35px;
		}

		.logo-lg {
			height: 65px;
		}
	}

	&-info {
		margin: 0 auto;
		max-width: 600px;
		text-align: left;

		p {
			line-height: 1.4;
			color: $white;
			font-size: 12px;
		}
	}

	&-patient {
		.dscp-footer-logos {
			width: 400px;
		}
		.dscp-footer-legal-wrapper {
			width: 380px;
		}
		.dscp-footer-info {
			width: calc(100% - 780px);
			padding: 0 10px 0 20px;
		}
	}

	&-hcp {
		.dscp-footer-logos {
			width: 400px;
		}
		.dscp-footer-legal-wrapper {
			width: 270px;
		}
		.dscp-footer-info {
			width: calc(100% - 670px);
			padding: 0 10px 0 20px;
		}
	}

}

@media(max-width: 1023px) {

	.dscp-footer {
		&-upper {
			flex-wrap: wrap;
		}

		&-legal-wrapper {
			display: flex;
			justify-content: center;
			width: 100%;
		}

		&-logos {
			flex-wrap: wrap;
			justify-content: center;
			width: 100%;
		}

		&-patient {
			.dscp-footer-logos {
				width: 100%;
			}
			.dscp-footer-legal-wrapper {
				width: 100%;
			}
			.dscp-footer-info {
				width: 100%;
				padding: 10px 0;
			}
		}

		&-hcp {
			.dscp-footer-logos {
				width: 100%;
			}
			.dscp-footer-legal-wrapper {
				width: 100%;
			}
			.dscp-footer-info {
				width: 100%;
				padding: 10px 0;
			}
		}
	}

}
