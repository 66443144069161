//Fonts
@font-face {
	font-family: 'CeraPRO-Black';
	src: url( '../fonts/CeraPRO-Black.otf' ) format( 'opentype' );
}
@font-face {
	font-family: 'CeraPRO-Bold';
	src: url( '../fonts/CeraPRO-Bold.otf' ) format( 'opentype' );
}
@font-face {
	font-family: 'CeraPRO-Medium';
	src: url( '../fonts/CeraPRO-Medium.otf' ) format( 'opentype' );
}
@font-face {
	font-family: 'CeraPRO-Regular';
	src: url( '../fonts/CeraPRO-Regular.otf' ) format( 'opentype' );
}

//Variables
$black: #000;
$black2: #2f2f2f;
$white: #fff;
$grey: #6b6b6b;
$light-grey: #d4d4d4;
$dark-grey: #262626;

// $blue: #004063;
// $mid-blue: #2a7785;
// $cyan: #87d3e1;
// $light-cyan: #b4eef9;

$teal: rgb( 18, 157, 192 );
$teal-hover: #0f89a8;
$teal-70: rgba( 18, 157, 192, 0.7 );
$blue-grey: rgb( 107, 133, 153 );
$blue-grey2: rgb( 139, 160, 176 );
$navy: rgb( 23, 64, 96 );
$navy-hover: #1885a0;
$light-blue: rgb( 135, 211, 225 );
$pale-blue-95: rgba( 236, 248, 250, 0.95 );
$pale-blue-50: rgba( 236, 248, 250, 0.5 );
$gradient-blue-40: rgba( 231, 246, 249, 0.4 );
$gradient-blue-20: rgba( 231, 246, 249, 0.2 );
// $gradient-grey-1: #dfecef;
// $gradient-grey-2: #e2f2f4;
$gradient-grey-1: rgba( 211, 220, 226, 0.4 );
$gradient-grey-2: rgba( 211, 220, 226, 0.2 );
$white-50: rgba( 255, 255, 255, 0.5 );
$white-70: rgba( 255, 255, 255, 0.7 );
$white-90: rgba( 255, 255, 255, 0.9 );
$black-40: rgba( 0, 0, 0, 0.4 );
$pale-blue: rgb( 236, 248, 250 );
$pale-blue-hover: #b4e1e8;
$purple: rgb( 60, 14, 54 );
$purple-hover: #65185b;
$red: rgba( 255, 23, 16, 1 );


$cera-black: 'CeraPRO-Black', sans-serif;
$cera-bold: 'CeraPRO-Bold', sans-serif;
$cera-med: 'CeraPRO-Medium', sans-serif;
$cera-reg: 'CeraPRO-Regular', sans-serif;
