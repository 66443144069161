//Globals 
.page-wrapper {
	background: url('../img/page-bg.jpg') $white;
	background-repeat: no-repeat;
	background-size: 100% auto;
	background-position: bottom;
	padding-bottom: 100px;
}
.page-wrapper-home {
	background: url('../img/page-bg.jpg') #fff;
	background-repeat: no-repeat;
	background-size: 100% auto;
	background-position: bottom;
}
.content-wrapper {
	background: $white;
	border-radius: 10px;
	margin-top: 25px !important;
	margin-bottom: 25px !important;

}
.outer-wrapper {
	width: 100%;
	padding: 0 20px;
}

.inner-wrapper {
	margin: 0px auto;
	max-width: 1200px;
}

.inner-padding {
	padding: 25px 80px;
}
.inner-xs-padding {
	padding: 25px;
}

.inner-padding-bottom-0 {
	padding: 50px 80px 0 80px;
}

.padding-bottom {
	padding: 0 0 50px 0;
}

.gutter-right {
	padding: 0 20px 0 0;
}

.gutter-left {
	padding: 0 0 0 20px;
}

.inner-heading-padding {
	padding: 10px 20px;
}

.inner-sm-padding {
	padding: 25px 80px;
}

.hide-visually {
	position: absolute;
	border: 0;
	padding: 0;
	width: 1px;
	height: 1px;
	overflow: hidden;
	white-space: nowrap;
	clip: rect( 1px, 1px, 1px, 1px );
	clip-path: inset( 100% );
}

.center-items {
	display: flex;
	justify-content: center;
	padding-bottom: 30px;
}

@media(max-width: 779px) and (min-width: 480px) {

	.inner-padding {
		padding: 20px 40px;
	}

	.inner-padding-bottom-0 {
		padding: 20px 40px 0 40px;
	}

	.inner-heading-padding {
		padding: 10px;
	}

	.inner-sm-padding {
		padding: 20px 40px;
	}

}

@media(max-width: 479px) {

	.inner-padding {
		padding: 20px;
	}

	.inner-padding-bottom-0 {
		padding: 20px 20px 0 20px;
	}

	.inner-heading-padding {
		padding: 10px;
	}

	.inner-sm-padding {
		padding: 20px 10px;
	}

}
