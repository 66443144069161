// *:focus {
// 	outline: 2px solid $teal;
// 	border-radius: 2px;
// 	box-shadow: 0 0 10px $pale-blue;
// }

html {
	scroll-behavior: smooth;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

body {
    background: $navy;
}

h1,
h2,
h3,
h4 {
	margin: 36px 0 24px 0;
	color: $teal;
	font-family: $cera-bold;
}

h2 {
	font-size: 40px;
}

h3 {
	color: $navy;
	font-size: 30px;
}

h4 {
	margin: 24px 0 6px;
	line-height: 1.4;
	color: $blue-grey;
	font-family: $cera-bold;
	font-size: 18px;
}

p {
	margin: 12px 0;
	line-height: 1.4;
	color: $dark-grey;
	font-family: $cera-reg;
	font-size: 18px;

	strong {
		font-family: $cera-bold;
	}

	a {
		color: $teal;
	}
}

a {
	transition: 0.3s;
	color: $teal;

	&:hover,
	&:focus {
		transition: 0.3s;
		color: $navy;
	}
}

ol,
ul {
	margin: 24px 0;
	padding-left: 20px;
	line-height: 1.4;
	color: $dark-grey;
	font-family: $cera-reg;
	font-size: 18px;

	li {
		margin: 2px 0;
	}
}

ul.blue-marker {
	list-style: none;
	li {
		position: relative;
		&::before {
		  content: "• ";
		  color: $teal;
		  position: absolute;
		  left: -12px;
		}
	}
	a {
		color: $teal;
		&:hover {
			color: $navy;
		}
	}
}

ol {
	list-style: none;
	counter-reset: num;

	> li {
		counter-increment: num;

		&::before {
			display: inline-block;
			margin-left: -1em;
			width: 1em;
			color: $navy;
			font-family: $cera-med;
			content: counter( num )'. ';
		}
	}

	ul {
		margin-top: 0;
		padding-left: 20px;
		list-style-type: disc;
	}
}

address {
	font-style: normal;
}

input {

	&[type="text"],
	&[type="email"] {
		border: 1px solid $navy;
		padding: 5px 8px;
		font-family: $cera-med;
		color: $blue-grey;
		width: 100%;
		transition: 0.3s;

		&:focus,
		&:hover {
			transition: 0.3s;
			border: 1px solid $teal;
			outline: 1px solid $teal;
			box-shadow: 0px 0px 10px rgba(0,0,0,0.25);
		}
	}

	&[type="checkbox"],
	&[type="radio"] {
		appearance: none;
		border: 1px solid $navy;
		height: 18px;
		width: 18px;
		border-radius: 50%;
		position: relative;
		cursor: pointer;
		&:checked {
			background: $teal;
			border: 1px solid $teal;
			&:before {
				content: '';
				height: 6px;
				width: 12px;
				display: block;
				position: absolute;
				border-left: 2px solid $white;
				border-bottom: 2px solid $white; 
				top: 4px;
				left: 2px;
				transform: rotate( -45deg );
			}
		}
		&:focus,
		&:hover {
			transition: 0.3s;
			border: 1px solid $teal;
			outline: 1px solid $teal;
			box-shadow: 0px 0px 10px rgba(0,0,0,0.25);
		}
	}

	&[type="submit"] {
		transition: 0.3s;
		margin: 10px 0;
		outline: 0;
		border: 0;
		border-radius: 4px;
		background: $teal;
		padding: 10px 40px;
		text-transform: uppercase;
		color: $white;
		font-family: $cera-med;
		font-size: 18px;
		text-decoration: none;
		display: inline-block;

		&:focus,
		&:hover {
			transition: 0.3s;
			background: $teal-hover;
			color: $white;
		}
	}
}

textarea {
	border: 1px solid $navy;
	padding: 8px;
	font-family: $cera-med;
	color: $blue-grey;
	width: 100%;
	transition: 0.3s;

	&:focus,
	&:hover {
		transition: 0.3s;
		border: 1px solid $teal;
		outline: 1px solid $teal;
		box-shadow: 0px 0px 10px rgba(0,0,0,0.25);
	}
}

select {
	width: 100%;
}

label {
	font-family: $cera-med;
	font-size: 16px;
	color: $blue-grey;
	line-height: 1.2;
	a {
		color: $teal;
	}
}

@media (max-width: 779px) {

	h2 {
		font-size: 30px;
	}

	h3 {
		color: $navy;
		font-size: 24px;
	}

	h4 {
		font-size: 16px;
	}

	p {
		font-size: 16px;
		line-height: 1.35;
	}

	ol,
	ul {
		font-size: 16px;
	}
}

@media(max-width: 479px) {
	h2 {
		font-size: 22px;
	}

	h3 {
		font-size: 20px;
	}
	input {
		&[type="submit"] {
			padding: 10px 20px;
		}
	}
}
