.h3-underline-wrapper {
	display: flex;
	justify-content: center;
	padding-bottom: 30px;
}

.h3-underline {
	display: inline-block;
	position: relative;
	margin: 0 auto;
	text-align: center;
	line-height: 1.4;

	&::after {
		display: block;
		position: absolute;
		left: 15%;
		background: $navy;
		width: 70%;
		height: 4px;
		content: '';
	}

}

.h3-safety {
	text-decoration: underline;
	font-size: 20px;
	color: $navy;
}

.h3-underline-left {
	display: inline-block;
	position: relative;
	margin: 0 auto;
	text-align: left;
	line-height: 1.4;

	&::after {
		display: block;
		position: absolute;
		left: 0%;
		background: $navy;
		width: 50%;
		height: 4px;
		content: '';
	}

}

.center-align {
	text-align: center;
}
.center-button {
	display: flex;
	justify-content: center;
	padding: 20px 0;
}
.flex-center {
	display: flex;
	align-items: center;
	justify-content: center;
}

.p-blue {
	color: $navy;
}

.p-teal {
	color: $teal;
}
.p-bold {
	font-family: $cera-bold;
}
.p-center {
	text-align: center;
}
.p-em {
	margin: 0 0 12px 0;
	font-family: $cera-reg;
}

.p-med {
	font-family: $cera-med;
}

.p-alt {
	color: $teal;
	font-family: $cera-bold;
	text-align: center;
	margin: 50px 0 2px 0;
	font-size: 24px;
	line-height: 1.2;
	a {
		color: $teal;
		&:hover {
			color: $navy;
		}
	}
}

.p-alt-navy {
	color: $navy;
	font-family: $cera-bold;
	text-align: center;
	margin: 50px 0 2px 0;
	font-size: 24px;
	line-height: 1.2;
	a {
		color: $navy;
		&:hover {
			color: $teal;
		}
	}
}

.p-form-message {
	font-family: $cera-reg;
	color: $blue-grey;
	font-size: 16px;
	line-height: 1.2;
}

.label-navy {
	color: $navy;
	font-family: $cera-bold;
	margin: 50px 0 20px 0;
	font-size: 24px;
	display: block;
}

.p-top-margin {
	margin-top: 60px;
}

.p-margin-0 {
	margin: 0;
}

.p-margin-12 {
	margin: 12px 0;
}

.p-800 {
	margin-right: auto;
	margin-left: auto;
	max-width: 800px;
}
.right-5 {
	padding-right: 5px !important;
}
.p-disclaimer {
	margin-top: 100px;
	font-size: 16px;
}

.p-ref {
	font-size: 14px;
	margin-top: 50px;
	line-height: 2;
}

.h2-fz-40 {
	font-size: 40px;
}

.teal-btn {
	transition: 0.3s;
	margin: 10px 0;
	outline: 0;
	border: 0;
	border-radius: 4px;
	background: $teal;
	padding: 10px 40px;
	text-transform: uppercase;
	color: $white;
	font-family: $cera-med;
	font-size: 18px;
	text-decoration: none;
	display: inline-block;

	&:focus,
	&:hover {
		transition: 0.3s;
		background: $teal-hover;
		color: $white;
	}
}

.ul-dash {
	list-style: none;
	margin: 2px 0;
	> li {
		&::before {
			display: inline-block;
			margin-left: -1em;
			width: 1em;
			color: $blue-grey;
			font-family: $cera-med;
			content: '- ';
		}
	}
}

.grey-border {
	border: 1px solid #cecece;
}

//grid
.flex-container {
	display: flex;
}
.align-bottom {
	align-items: flex-end;
}
.flex-column {
	flex-direction: column;
}
.half {
	width: 50%;
}

//custom form elements

.cb-checkbox {
	display: flex;
	align-items: center;
	.cb-input {
		// width: 30px;
		// height: 30px;
		margin: 2px 8px;
	}
	.cb-label {
		width: calc(100% - 30px);
	}
}

.form-field {
	padding: 1px 0;
}

//select css - modified from https://github.com/filamentgroup/select-css/blob/master/src/select-css.css

/* class applies to select element itself, not a wrapper element */
.select-css {
	display: block;
	font-size: 16px;
	font-family: $cera-reg;
	font-weight: 700;
	color: $blue-grey;
	line-height: 1.4;
	padding: 2px 10px;
	width: 100%;
	max-width: 100%; /* useful when width is set to anything other than 100% */
	box-sizing: border-box;
	margin: 0;
	border: 1px solid $navy;
	box-shadow: 0;
	border-radius: 0;
	-moz-appearance: none;
	-webkit-appearance: none;
	appearance: none;
	background-color: #fff;
	/* note: bg image below uses 2 urls. The first is an svg data uri for the arrow icon, and the second is the gradient. 
		for the icon, if you want to change the color, be sure to use `%23` instead of `#`, since it's a url. You can also swap in a different svg icon or an external image reference
		
	*/
	background-image: url('data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22%23007CB2%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E'),
	  linear-gradient(to bottom, #ffffff 0%,#ffffff 100%);
	background-repeat: no-repeat, repeat;
	/* arrow icon position (1em from the right, 50% vertical) , then gradient position*/
	background-position: right .7em top 50%, 0 0;
	/* icon size, then gradient */
	background-size: .65em auto, 100%;
}
/* Hide arrow icon in IE browsers */
.select-css::-ms-expand {
	display: none;
}
/* Hover style */
.select-css:hover {
	border: 1px solid $teal;
	outline: 1px solid $teal;
	box-shadow: 0px 0px 10px rgba(0,0,0,0.25);
}
/* Focus style */
.select-css:focus {
	border-color: $teal;
	/* It'd be nice to use -webkit-focus-ring-color here but it doesn't work on box-shadow */
	box-shadow: 0px 0px 10px rgba(0,0,0,0.25);
	box-shadow: 0 0 0 3px -moz-mac-focusring;
	color: #222; 
	outline: none;
}

/* Set options to normal weight */
.select-css option {
	font-weight:normal;
}

/* Support for rtl text, explicit support for Arabic and Hebrew */
*[dir="rtl"] .select-css, :root:lang(ar) .select-css, :root:lang(iw) .select-css {
	background-position: left .7em top 50%, 0 0;
	padding: .6em .8em .5em 1.4em;
}

/* Disabled styles */
.select-css:disabled, .select-css[aria-disabled=true] {
	color: graytext;
	background-image: url('data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22graytext%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E'),
	  linear-gradient(to bottom, #ffffff 0%,#e5e5e5 100%);
}

.select-css:disabled:hover, .select-css[aria-disabled=true] {
	border-color: #aaa;
}

#message-form.-submitted,
#message-form:not(.-submitted) + .form-confirmation {
    display: none;
}

#message-form.-submitted + .form-confirmation {
    display: block;
    margin: 3em;
    font-size: 2em;
}

#message-form.-submitted ~ *:not(.form-confirmation) {
    display: none;
}

@media (max-width: 779px) {

	.p-disclaimer {
		margin-top: 50px;
		font-size: 14px;
	}

	.h2-fz-40 {
		font-size: 30px;
	}
}


@media(max-width: 479px) {
	.p-alt,
	.p-alt-navy,
	.label-navy {
		font-size: 20px;
	}

	.teal-btn {
		font-size: 16px;
		padding: 10px 16px;
	}

	.p-ref {
		line-height: 1.2;
	}
}