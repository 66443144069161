.site-gate {

	h2 {
		font-size: 20px;
		color: $navy;
		margin: 20px 0;
		text-align: center;
	}

	p {
		font-family: $cera-reg;
		color: $blue-grey;
		text-align: center;
		font-size: 16px;
		line-height: 1.2;
	}

	&-form {
		background: $white;
		border-radius: 10px;
		padding: 20px;
		// min-height: 400px;
		flex: 1 1 auto;
	}

	&-field {
		padding: 10px 0;
	}

	&-error {
		
		display: flex;
		justify-content: center;
		
		p {
			position: relative;
			padding-left: 30px;
			color: $red;
			margin-top: 0;
			&:before {
				content: '!';
				position: absolute;
				display: block;
				width: 16px;
				height: 16px;
				font-size: 14px;
				left: 5px;
				background: $red;
				border-radius: 50%;
				color: $white;
				font-family: $cera-bold;
			}
			
		}
	}
}

.button-question {
	background: $teal;
	height: 16px !important;
	width: 16px !important;
	font-size: 12px;
	display: inline-block;
	color: $white;
	border: 0;
	outline: 0;
	font-family: $cera-med;
	border-radius: 50%;
	position: relative;
	vertical-align: super;
	margin: 0 5px;
	.question {
		position: absolute;
		top: 2px;
		left: 5px;
	}
}

.open-din-info {
	transition: 0.3s;
	margin: 12px 0 0 0;
	outline: 0;
	border: 0;
	width: 100%;
	background: transparent;
	text-align: center;
	text-decoration: underline;
	color: $teal;
	font-family: $cera-med;

	&:hover,
	&:focus {
		transition: 0.3s;
		color: $teal-hover;
	}
}

@media (max-width: 1023px) and (min-width: 780px) {


}

@media (min-width: 1023px) {
	.tab-label,
	.tab-input {
		display: none;
	}
	.form-section-wrapper {
		display: flex;
	}
	.form-section {
		display: flex;
		width: 50%;
	}
	.site-gate {

		&-wrapper {
			position: relative;
			display: flex;
			&-hcp {
				padding-left: 150px;
				padding-right: 10px;
			}
			&-pat {
				padding-right: 150px;
				padding-left: 10px;
			}
		}

		&-form-outer {
			flex-direction: column;
			display: flex;
		}
			
		&-img {
			max-width: 200px;
			height: auto;
			position: absolute;
			bottom: 0;
			&-hcp {
				left: 0;
			}
			&-pat {
				right: 0;
			}
		}
		&-form {
			&-hcp {
				padding-left: 60px;
			}
			&-pat {
				padding-right: 60px;
			}
		}
	}
	.form-img-p-hcp-fr {
		padding-left: 60px;
		font-size: 14px !important;
		text-align: left !important;
	}
	.form-img-p-pat-fr {
		padding-right: 50px;
		font-size: 14px !important;
		text-align: right !important;
	}
	.form-img-p-hcp {
		padding-left: 60px;
		text-align: left !important;
	}
	.form-img-p-pat {
		padding-right: 50px;
		text-align: right !important;
	}
}

@media (max-width: 1023px) {

	.site-gate {
		&-img {
			display: none;
		}
	}

	.tabs-form {
		position: relative;   
		min-height: 550px; /* This part sucks */
		margin: 25px auto;
		max-width: 600px;
	}
	.tabs-form-fr {
		min-height: 600px; 
	}
	.tab-form {
		// float: left;
	}
	.tab-form .tab-label {
		background: #eee; 
		padding: 10px 10px 10px 20px; 
		border: 1px solid #f2f2f2; 
		margin-left: -1px; 
		position: absolute;
		height: 41px;
		width: 50%;
		transition: 0.3s;
		cursor: pointer;
		display: flex;
		align-items: center;

		&-pat {
			right: 0;
			top: 0;
	  		border-top-right-radius: 10px;
		}	

		&-hcp {
			left: 1px; 
			top: 0;
			border-top-left-radius: 10px;
		}

		&:hover,
		&:focus {
	  		color: $teal;
	  		transition: 0.3s;
	  	}
	}
	.tab-form .tab-input {
	  display: none;   
	}
	.tab-content {
		position: absolute;
		top: 40px;
		left: 0;
		background: white;
		right: 0;
		bottom: 0;
		padding: 0;
		border: 1px solid #f2f2f2; 
		border-bottom-right-radius: 10px;
		border-bottom-left-radius: 10px;
	}
	.tab-input:checked ~ label {
		background: white;
		border-bottom: 1px solid white;
		z-index: 2;
		color: $teal;
	}
	.tab-input:checked ~ .tab-label ~ .tab-content {
		z-index: 1;
	}
	.form-img-p {
		display: none;
	}
}

@media(max-width: 479px) {
	.tab-content {
		top: 60px;
	}
	.tab-form .tab-label {
		height: 61px;
	}
	.site-gate {
		h2 {
			margin: 10px 0;
		}
		&-form {
			padding: 10px 15px;
		}
	}
}
