.din-bg {
	background-color: $pale-blue;
	background-image: url('../img/din-gradient-bg.jpg');
	background-repeat: no-repeat;
	background-size: 100% auto;

	h2 {
		margin-bottom: 6px;
	}

	h3 {
		margin: 6px 0 36px 0;
		font-family: $cera-reg;
		font-size: 24px;
	}
}

.din-modal,
.interstitual-modal {
	margin: 20px 0;
	border-radius: 10px;
	background: $white-90;
	padding: 50px;

	&-inner {
		margin: 0 auto;
		max-width: 800px;
		// width: 100%;
	}

	label {
		margin: 10px 0;
		line-height: 1.4;
		color: $blue-grey;
		font-family: $cera-med;
		font-size: 18px;
	}

	input {
		transition: 0.3s;
		margin: 10px 0;
		border: 2px solid $light-grey;
		box-shadow: inset 0 0 3px $light-grey;
		background: $white;
		padding: 10px;
		width: 100%;
		color: $black;
		font-family: $cera-med;
		font-size: 18px;

		&:hover,
		&:focus {
			transition: 0.3s;
			outline: 0;
			border: 2px solid $teal;
		}
	}

	input[type='submit'],
	button {
		// background: $teal;
		// font-family: $cera-med;
		// font-size: 18px;
		// color: $white;
		// margin: 10px 0;
		// text-transform: uppercase;
		// padding: 10px 40px;	
		// outline: 0;
		// border: 0;
		// border-radius: 4px;
	}

	.open-din-info {
		transition: 0.3s;
		margin: 12px 0 0 0;
		outline: 0;
		border: 0;
		background: transparent;
		text-align: center;
		text-decoration: underline;
		color: $blue-grey;
		font-family: $cera-med;

		&:hover,
		&:focus {
			transition: 0.3s;
			color: $navy;
		}
	}
}

.interstitual-modal .flex-center > * {
    margin: 0 0.5em;
}

.qr-banner {
	margin: 36px 0;
	border-radius: 20px;
	background: $purple;
	padding: 20px;

	&-inner {
		display: flex;
		align-items: center;
		margin: 0 auto;
		max-width: 700px;
	}

	&-text {
		width: calc( 100% - 120px );
	}

	&-img-wrapper {
		position: relative;
		width: 120px;
		height: 60px;

		img {
			display: block;
			position: absolute;
			top: -50px;
			width: 125%;
			height: auto;
		}
	}

	p {
		margin: 12px 0;
		line-height: 1.2;
		color: $white;
	}
}

body.modal-open {
	position: relative;
	overflow: hidden;
}

.modal-1,
.modal-2,
.modal-3 {
	display: none;

	&.overlay.visible {
		display: flex;
		position: fixed;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		align-items: center;
		justify-content: center;
		background: $black-40;
		width: 100%;
		height: 100vh;
		z-index: 20;
	}

	.din-info-modal {
		border-radius: 10px;
		background: $white;
		padding: 30px 80px;

		&-inner {
			display: flex;
			align-items: flex-start;
			margin: 0 auto;
			max-width: 600px;

			img {
				display: flex;
				width: 300px;
				height: auto;
			}

		}
	}
}
.modal-1 {
	.din-info-modal {
		&-inner {
			p {
				margin-top: 70px;
				margin-left: -145px;
			}
		}
	}
}

.modal-3 {
	.din-info-modal {
		width: 100%;
		max-width: 700px;
	}
	.flex-container {
		padding-bottom: 20px;
	}
}

.hiv-status-form {
	.flex-container {
		justify-content: center;
	}
	label {
		font-size: 20px;
		color: $teal;
	}
	p {
		color: $blue-grey;
		font-size: 16px;
		line-height: 1.2;
		text-align: center;
	}
}

.display-none {
	display: none !important;
}

@media (max-width: 779px) {

	.din-modal {
		label {
			font-size: 16px;
		}
	}

	.qr-banner {

		&-img-wrapper {
			position: relative;
			width: 120px;

			img {
				// display: block;
				// width: 100%;
				// top: -33px;
				// height: auto;
			}
		}
	}
}

@media (max-width: 479px) {

	.din-modal {
		padding: 30px 20px;
	}

	.modal-1,
	.modal-2,
	.modal-3 {

		.din-info-modal {
			padding: 30px 20px;

			&-inner {

				img {
					width: 200px;
				}

				p {
					// margin-top: 50px;
					// margin-left: -100px;
				}
			}
		}
		.site-gate-form {
			padding: 10px 0;
		}
	}
	.hiv-status-form {
		label {
			font-size: 16px;
		}
	}
	.site-gate-form-hcp {
		.flex-container {
			flex-wrap: wrap;
		}
		.right-5 {
			padding-right: 0 !important;
		}
		.half {
			width: 100%;
		}
	}
}
