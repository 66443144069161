@charset "UTF-8";
@font-face {
  font-family: 'CeraPRO-Black';
  src: url("../fonts/CeraPRO-Black.otf") format("opentype"); }

@font-face {
  font-family: 'CeraPRO-Bold';
  src: url("../fonts/CeraPRO-Bold.otf") format("opentype"); }

@font-face {
  font-family: 'CeraPRO-Medium';
  src: url("../fonts/CeraPRO-Medium.otf") format("opentype"); }

@font-face {
  font-family: 'CeraPRO-Regular';
  src: url("../fonts/CeraPRO-Regular.otf") format("opentype"); }

*,
*::before,
*::after {
  box-sizing: inherit; }

.clearfix::after {
  display: table;
  clear: both;
  content: ''; }

html {
  font-family: sans-serif;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  box-sizing: border-box; }

body {
  margin: 0; }

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
main,
menu,
nav,
section,
summary {
  display: block; }

audio,
canvas,
progress,
video {
  display: inline-block;
  vertical-align: baseline; }

audio:not([controls]) {
  display: none;
  height: 0; }

[hidden],
template {
  display: none; }

a {
  background-color: transparent; }

a:active,
a:hover {
  outline: 0; }

abbr[title] {
  border-bottom: 1px dotted; }

b,
strong {
  font-weight: 700; }

dfn {
  font-style: italic; }

h1 {
  margin: 0.67em 0;
  font-size: 2em; }

mark {
  background: #ff0;
  color: #000; }

small {
  font-size: 80%; }

sub,
sup {
  position: relative;
  vertical-align: baseline;
  line-height: 0;
  font-size: 75%; }

sup {
  top: -0.5em; }

sub {
  bottom: -0.25em; }

img {
  border: 0; }

svg:not(:root) {
  overflow: hidden; }

figure {
  margin: 1em 40px; }

hr {
  height: 0;
  -moz-box-sizing: content-box;
  box-sizing: content-box; }

pre {
  overflow: auto; }

code,
kbd,
pre,
samp {
  font-family: monospace, monospace;
  font-size: 1em; }

button,
input,
optgroup,
select,
textarea {
  margin: 0;
  color: inherit;
  font: inherit; }

button {
  overflow: visible; }

button,
select {
  text-transform: none; }

button,
html input[type='button'],
input[type='reset'],
input[type='submit'] {
  cursor: pointer;
  -webkit-appearance: button; }

button[disabled],
html input[disabled] {
  cursor: default; }

button::-moz-focus-inner,
input::-moz-focus-inner {
  border: 0;
  padding: 0; }

input {
  line-height: normal; }

input[type='checkbox'],
input[type='radio'] {
  box-sizing: border-box;
  padding: 0; }

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  height: auto; }

input[type='search'] {
  -webkit-appearance: textfield;
  -moz-box-sizing: content-box;
  -webkit-box-sizing: content-box;
  box-sizing: content-box; }

input[type='search']::-webkit-search-cancel-button,
input[type='search']::-webkit-search-decoration {
  -webkit-appearance: none; }

fieldset {
  margin: 0 2px;
  border: 1px solid silver;
  padding: 0.35em 0.625em 0.75em; }

legend {
  border: 0;
  padding: 0; }

textarea {
  overflow: auto; }

optgroup {
  font-weight: 700; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

td,
th {
  padding: 0; }

.page-wrapper {
  background: url("../img/page-bg.jpg") #fff;
  background-repeat: no-repeat;
  background-size: 100% auto;
  background-position: bottom;
  padding-bottom: 100px; }

.page-wrapper-home {
  background: url("../img/page-bg.jpg") #fff;
  background-repeat: no-repeat;
  background-size: 100% auto;
  background-position: bottom; }

.content-wrapper {
  background: #fff;
  border-radius: 10px;
  margin-top: 25px !important;
  margin-bottom: 25px !important; }

.outer-wrapper {
  width: 100%;
  padding: 0 20px; }

.inner-wrapper {
  margin: 0px auto;
  max-width: 1200px; }

.inner-padding {
  padding: 25px 80px; }

.inner-xs-padding {
  padding: 25px; }

.inner-padding-bottom-0 {
  padding: 50px 80px 0 80px; }

.padding-bottom {
  padding: 0 0 50px 0; }

.gutter-right {
  padding: 0 20px 0 0; }

.gutter-left {
  padding: 0 0 0 20px; }

.inner-heading-padding {
  padding: 10px 20px; }

.inner-sm-padding {
  padding: 25px 80px; }

.hide-visually {
  position: absolute;
  border: 0;
  padding: 0;
  width: 1px;
  height: 1px;
  overflow: hidden;
  white-space: nowrap;
  clip: rect(1px, 1px, 1px, 1px);
  clip-path: inset(100%); }

.center-items {
  display: flex;
  justify-content: center;
  padding-bottom: 30px; }

@media (max-width: 779px) and (min-width: 480px) {
  .inner-padding {
    padding: 20px 40px; }
  .inner-padding-bottom-0 {
    padding: 20px 40px 0 40px; }
  .inner-heading-padding {
    padding: 10px; }
  .inner-sm-padding {
    padding: 20px 40px; } }

@media (max-width: 479px) {
  .inner-padding {
    padding: 20px; }
  .inner-padding-bottom-0 {
    padding: 20px 20px 0 20px; }
  .inner-heading-padding {
    padding: 10px; }
  .inner-sm-padding {
    padding: 20px 10px; } }

html {
  scroll-behavior: smooth;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

body {
  background: #174060; }

h1,
h2,
h3,
h4 {
  margin: 36px 0 24px 0;
  color: #129dc0;
  font-family: "CeraPRO-Bold", sans-serif; }

h2 {
  font-size: 40px; }

h3 {
  color: #174060;
  font-size: 30px; }

h4 {
  margin: 24px 0 6px;
  line-height: 1.4;
  color: #6b8599;
  font-family: "CeraPRO-Bold", sans-serif;
  font-size: 18px; }

p {
  margin: 12px 0;
  line-height: 1.4;
  color: #262626;
  font-family: "CeraPRO-Regular", sans-serif;
  font-size: 18px; }
  p strong {
    font-family: "CeraPRO-Bold", sans-serif; }
  p a {
    color: #129dc0; }

a {
  transition: 0.3s;
  color: #129dc0; }
  a:hover, a:focus {
    transition: 0.3s;
    color: #174060; }

ol,
ul {
  margin: 24px 0;
  padding-left: 20px;
  line-height: 1.4;
  color: #262626;
  font-family: "CeraPRO-Regular", sans-serif;
  font-size: 18px; }
  ol li,
  ul li {
    margin: 2px 0; }

ul.blue-marker {
  list-style: none; }
  ul.blue-marker li {
    position: relative; }
    ul.blue-marker li::before {
      content: "• ";
      color: #129dc0;
      position: absolute;
      left: -12px; }
  ul.blue-marker a {
    color: #129dc0; }
    ul.blue-marker a:hover {
      color: #174060; }

ol {
  list-style: none;
  counter-reset: num; }
  ol > li {
    counter-increment: num; }
    ol > li::before {
      display: inline-block;
      margin-left: -1em;
      width: 1em;
      color: #174060;
      font-family: "CeraPRO-Medium", sans-serif;
      content: counter(num) ". "; }
  ol ul {
    margin-top: 0;
    padding-left: 20px;
    list-style-type: disc; }

address {
  font-style: normal; }

input[type="text"], input[type="email"] {
  border: 1px solid #174060;
  padding: 5px 8px;
  font-family: "CeraPRO-Medium", sans-serif;
  color: #6b8599;
  width: 100%;
  transition: 0.3s; }
  input[type="text"]:focus, input[type="text"]:hover, input[type="email"]:focus, input[type="email"]:hover {
    transition: 0.3s;
    border: 1px solid #129dc0;
    outline: 1px solid #129dc0;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25); }

input[type="checkbox"], input[type="radio"] {
  appearance: none;
  border: 1px solid #174060;
  height: 18px;
  width: 18px;
  border-radius: 50%;
  position: relative;
  cursor: pointer; }
  input[type="checkbox"]:checked, input[type="radio"]:checked {
    background: #129dc0;
    border: 1px solid #129dc0; }
    input[type="checkbox"]:checked:before, input[type="radio"]:checked:before {
      content: '';
      height: 6px;
      width: 12px;
      display: block;
      position: absolute;
      border-left: 2px solid #fff;
      border-bottom: 2px solid #fff;
      top: 4px;
      left: 2px;
      transform: rotate(-45deg); }
  input[type="checkbox"]:focus, input[type="checkbox"]:hover, input[type="radio"]:focus, input[type="radio"]:hover {
    transition: 0.3s;
    border: 1px solid #129dc0;
    outline: 1px solid #129dc0;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25); }

input[type="submit"] {
  transition: 0.3s;
  margin: 10px 0;
  outline: 0;
  border: 0;
  border-radius: 4px;
  background: #129dc0;
  padding: 10px 40px;
  text-transform: uppercase;
  color: #fff;
  font-family: "CeraPRO-Medium", sans-serif;
  font-size: 18px;
  text-decoration: none;
  display: inline-block; }
  input[type="submit"]:focus, input[type="submit"]:hover {
    transition: 0.3s;
    background: #0f89a8;
    color: #fff; }

textarea {
  border: 1px solid #174060;
  padding: 8px;
  font-family: "CeraPRO-Medium", sans-serif;
  color: #6b8599;
  width: 100%;
  transition: 0.3s; }
  textarea:focus, textarea:hover {
    transition: 0.3s;
    border: 1px solid #129dc0;
    outline: 1px solid #129dc0;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25); }

select {
  width: 100%; }

label {
  font-family: "CeraPRO-Medium", sans-serif;
  font-size: 16px;
  color: #6b8599;
  line-height: 1.2; }
  label a {
    color: #129dc0; }

@media (max-width: 779px) {
  h2 {
    font-size: 30px; }
  h3 {
    color: #174060;
    font-size: 24px; }
  h4 {
    font-size: 16px; }
  p {
    font-size: 16px;
    line-height: 1.35; }
  ol,
  ul {
    font-size: 16px; } }

@media (max-width: 479px) {
  h2 {
    font-size: 22px; }
  h3 {
    font-size: 20px; }
  input[type="submit"] {
    padding: 10px 20px; } }

.h3-underline-wrapper {
  display: flex;
  justify-content: center;
  padding-bottom: 30px; }

.h3-underline {
  display: inline-block;
  position: relative;
  margin: 0 auto;
  text-align: center;
  line-height: 1.4; }
  .h3-underline::after {
    display: block;
    position: absolute;
    left: 15%;
    background: #174060;
    width: 70%;
    height: 4px;
    content: ''; }

.h3-safety {
  text-decoration: underline;
  font-size: 20px;
  color: #174060; }

.h3-underline-left {
  display: inline-block;
  position: relative;
  margin: 0 auto;
  text-align: left;
  line-height: 1.4; }
  .h3-underline-left::after {
    display: block;
    position: absolute;
    left: 0%;
    background: #174060;
    width: 50%;
    height: 4px;
    content: ''; }

.center-align {
  text-align: center; }

.center-button {
  display: flex;
  justify-content: center;
  padding: 20px 0; }

.flex-center {
  display: flex;
  align-items: center;
  justify-content: center; }

.p-blue {
  color: #174060; }

.p-teal {
  color: #129dc0; }

.p-bold {
  font-family: "CeraPRO-Bold", sans-serif; }

.p-center {
  text-align: center; }

.p-em {
  margin: 0 0 12px 0;
  font-family: "CeraPRO-Regular", sans-serif; }

.p-med {
  font-family: "CeraPRO-Medium", sans-serif; }

.p-alt {
  color: #129dc0;
  font-family: "CeraPRO-Bold", sans-serif;
  text-align: center;
  margin: 50px 0 2px 0;
  font-size: 24px;
  line-height: 1.2; }
  .p-alt a {
    color: #129dc0; }
    .p-alt a:hover {
      color: #174060; }

.p-alt-navy {
  color: #174060;
  font-family: "CeraPRO-Bold", sans-serif;
  text-align: center;
  margin: 50px 0 2px 0;
  font-size: 24px;
  line-height: 1.2; }
  .p-alt-navy a {
    color: #174060; }
    .p-alt-navy a:hover {
      color: #129dc0; }

.p-form-message {
  font-family: "CeraPRO-Regular", sans-serif;
  color: #6b8599;
  font-size: 16px;
  line-height: 1.2; }

.label-navy {
  color: #174060;
  font-family: "CeraPRO-Bold", sans-serif;
  margin: 50px 0 20px 0;
  font-size: 24px;
  display: block; }

.p-top-margin {
  margin-top: 60px; }

.p-margin-0 {
  margin: 0; }

.p-margin-12 {
  margin: 12px 0; }

.p-800 {
  margin-right: auto;
  margin-left: auto;
  max-width: 800px; }

.right-5 {
  padding-right: 5px !important; }

.p-disclaimer {
  margin-top: 100px;
  font-size: 16px; }

.p-ref {
  font-size: 14px;
  margin-top: 50px;
  line-height: 2; }

.h2-fz-40 {
  font-size: 40px; }

.teal-btn {
  transition: 0.3s;
  margin: 10px 0;
  outline: 0;
  border: 0;
  border-radius: 4px;
  background: #129dc0;
  padding: 10px 40px;
  text-transform: uppercase;
  color: #fff;
  font-family: "CeraPRO-Medium", sans-serif;
  font-size: 18px;
  text-decoration: none;
  display: inline-block; }
  .teal-btn:focus, .teal-btn:hover {
    transition: 0.3s;
    background: #0f89a8;
    color: #fff; }

.ul-dash {
  list-style: none;
  margin: 2px 0; }
  .ul-dash > li::before {
    display: inline-block;
    margin-left: -1em;
    width: 1em;
    color: #6b8599;
    font-family: "CeraPRO-Medium", sans-serif;
    content: '- '; }

.grey-border {
  border: 1px solid #cecece; }

.flex-container {
  display: flex; }

.align-bottom {
  align-items: flex-end; }

.flex-column {
  flex-direction: column; }

.half {
  width: 50%; }

.cb-checkbox {
  display: flex;
  align-items: center; }
  .cb-checkbox .cb-input {
    margin: 2px 8px; }
  .cb-checkbox .cb-label {
    width: calc(100% - 30px); }

.form-field {
  padding: 1px 0; }

/* class applies to select element itself, not a wrapper element */
.select-css {
  display: block;
  font-size: 16px;
  font-family: "CeraPRO-Regular", sans-serif;
  font-weight: 700;
  color: #6b8599;
  line-height: 1.4;
  padding: 2px 10px;
  width: 100%;
  max-width: 100%;
  /* useful when width is set to anything other than 100% */
  box-sizing: border-box;
  margin: 0;
  border: 1px solid #174060;
  box-shadow: 0;
  border-radius: 0;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  background-color: #fff;
  /* note: bg image below uses 2 urls. The first is an svg data uri for the arrow icon, and the second is the gradient. 
		for the icon, if you want to change the color, be sure to use `%23` instead of `#`, since it's a url. You can also swap in a different svg icon or an external image reference
		
	*/
  background-image: url("data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22%23007CB2%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E"), linear-gradient(to bottom, #ffffff 0%, #ffffff 100%);
  background-repeat: no-repeat, repeat;
  /* arrow icon position (1em from the right, 50% vertical) , then gradient position*/
  background-position: right .7em top 50%, 0 0;
  /* icon size, then gradient */
  background-size: .65em auto, 100%; }

/* Hide arrow icon in IE browsers */
.select-css::-ms-expand {
  display: none; }

/* Hover style */
.select-css:hover {
  border: 1px solid #129dc0;
  outline: 1px solid #129dc0;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25); }

/* Focus style */
.select-css:focus {
  border-color: #129dc0;
  /* It'd be nice to use -webkit-focus-ring-color here but it doesn't work on box-shadow */
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
  box-shadow: 0 0 0 3px -moz-mac-focusring;
  color: #222;
  outline: none; }

/* Set options to normal weight */
.select-css option {
  font-weight: normal; }

/* Support for rtl text, explicit support for Arabic and Hebrew */
*[dir="rtl"] .select-css, :root:lang(ar) .select-css, :root:lang(iw) .select-css {
  background-position: left .7em top 50%, 0 0;
  padding: .6em .8em .5em 1.4em; }

/* Disabled styles */
.select-css:disabled, .select-css[aria-disabled=true] {
  color: graytext;
  background-image: url("data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22graytext%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E"), linear-gradient(to bottom, #ffffff 0%, #e5e5e5 100%); }

.select-css:disabled:hover, .select-css[aria-disabled=true] {
  border-color: #aaa; }

#message-form.-submitted,
#message-form:not(.-submitted) + .form-confirmation {
  display: none; }

#message-form.-submitted + .form-confirmation {
  display: block;
  margin: 3em;
  font-size: 2em; }

#message-form.-submitted ~ *:not(.form-confirmation) {
  display: none; }

@media (max-width: 779px) {
  .p-disclaimer {
    margin-top: 50px;
    font-size: 14px; }
  .h2-fz-40 {
    font-size: 30px; } }

@media (max-width: 479px) {
  .p-alt,
  .p-alt-navy,
  .label-navy {
    font-size: 20px; }
  .teal-btn {
    font-size: 16px;
    padding: 10px 16px; }
  .p-ref {
    line-height: 1.2; } }

.din-bg {
  background-color: #ecf8fa;
  background-image: url("../img/din-gradient-bg.jpg");
  background-repeat: no-repeat;
  background-size: 100% auto; }
  .din-bg h2 {
    margin-bottom: 6px; }
  .din-bg h3 {
    margin: 6px 0 36px 0;
    font-family: "CeraPRO-Regular", sans-serif;
    font-size: 24px; }

.din-modal,
.interstitual-modal {
  margin: 20px 0;
  border-radius: 10px;
  background: rgba(255, 255, 255, 0.9);
  padding: 50px; }
  .din-modal-inner,
  .interstitual-modal-inner {
    margin: 0 auto;
    max-width: 800px; }
  .din-modal label,
  .interstitual-modal label {
    margin: 10px 0;
    line-height: 1.4;
    color: #6b8599;
    font-family: "CeraPRO-Medium", sans-serif;
    font-size: 18px; }
  .din-modal input,
  .interstitual-modal input {
    transition: 0.3s;
    margin: 10px 0;
    border: 2px solid #d4d4d4;
    box-shadow: inset 0 0 3px #d4d4d4;
    background: #fff;
    padding: 10px;
    width: 100%;
    color: #000;
    font-family: "CeraPRO-Medium", sans-serif;
    font-size: 18px; }
    .din-modal input:hover, .din-modal input:focus,
    .interstitual-modal input:hover,
    .interstitual-modal input:focus {
      transition: 0.3s;
      outline: 0;
      border: 2px solid #129dc0; }
  .din-modal .open-din-info,
  .interstitual-modal .open-din-info {
    transition: 0.3s;
    margin: 12px 0 0 0;
    outline: 0;
    border: 0;
    background: transparent;
    text-align: center;
    text-decoration: underline;
    color: #6b8599;
    font-family: "CeraPRO-Medium", sans-serif; }
    .din-modal .open-din-info:hover, .din-modal .open-din-info:focus,
    .interstitual-modal .open-din-info:hover,
    .interstitual-modal .open-din-info:focus {
      transition: 0.3s;
      color: #174060; }

.interstitual-modal .flex-center > * {
  margin: 0 0.5em; }

.qr-banner {
  margin: 36px 0;
  border-radius: 20px;
  background: #3c0e36;
  padding: 20px; }
  .qr-banner-inner {
    display: flex;
    align-items: center;
    margin: 0 auto;
    max-width: 700px; }
  .qr-banner-text {
    width: calc( 100% - 120px); }
  .qr-banner-img-wrapper {
    position: relative;
    width: 120px;
    height: 60px; }
    .qr-banner-img-wrapper img {
      display: block;
      position: absolute;
      top: -50px;
      width: 125%;
      height: auto; }
  .qr-banner p {
    margin: 12px 0;
    line-height: 1.2;
    color: #fff; }

body.modal-open {
  position: relative;
  overflow: hidden; }

.modal-1,
.modal-2,
.modal-3 {
  display: none; }
  .modal-1.overlay.visible,
  .modal-2.overlay.visible,
  .modal-3.overlay.visible {
    display: flex;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    align-items: center;
    justify-content: center;
    background: rgba(0, 0, 0, 0.4);
    width: 100%;
    height: 100vh;
    z-index: 20; }
  .modal-1 .din-info-modal,
  .modal-2 .din-info-modal,
  .modal-3 .din-info-modal {
    border-radius: 10px;
    background: #fff;
    padding: 30px 80px; }
    .modal-1 .din-info-modal-inner,
    .modal-2 .din-info-modal-inner,
    .modal-3 .din-info-modal-inner {
      display: flex;
      align-items: flex-start;
      margin: 0 auto;
      max-width: 600px; }
      .modal-1 .din-info-modal-inner img,
      .modal-2 .din-info-modal-inner img,
      .modal-3 .din-info-modal-inner img {
        display: flex;
        width: 300px;
        height: auto; }

.modal-1 .din-info-modal-inner p {
  margin-top: 70px;
  margin-left: -145px; }

.modal-3 .din-info-modal {
  width: 100%;
  max-width: 700px; }

.modal-3 .flex-container {
  padding-bottom: 20px; }

.hiv-status-form .flex-container {
  justify-content: center; }

.hiv-status-form label {
  font-size: 20px;
  color: #129dc0; }

.hiv-status-form p {
  color: #6b8599;
  font-size: 16px;
  line-height: 1.2;
  text-align: center; }

.display-none {
  display: none !important; }

@media (max-width: 779px) {
  .din-modal label {
    font-size: 16px; }
  .qr-banner-img-wrapper {
    position: relative;
    width: 120px; } }

@media (max-width: 479px) {
  .din-modal {
    padding: 30px 20px; }
  .modal-1 .din-info-modal,
  .modal-2 .din-info-modal,
  .modal-3 .din-info-modal {
    padding: 30px 20px; }
    .modal-1 .din-info-modal-inner img,
    .modal-2 .din-info-modal-inner img,
    .modal-3 .din-info-modal-inner img {
      width: 200px; }
  .modal-1 .site-gate-form,
  .modal-2 .site-gate-form,
  .modal-3 .site-gate-form {
    padding: 10px 0; }
  .hiv-status-form label {
    font-size: 16px; }
  .site-gate-form-hcp .flex-container {
    flex-wrap: wrap; }
  .site-gate-form-hcp .right-5 {
    padding-right: 0 !important; }
  .site-gate-form-hcp .half {
    width: 100%; } }

.dscp-header-inner {
  display: flex;
  align-items: center;
  justify-content: space-between; }

.dscp-header-center {
  display: flex;
  justify-content: center;
  padding-bottom: 50px;
  width: 100%; }

.dscp-header-logo {
  width: 175px;
  height: auto; }

.nav-wrapper {
  display: flex;
  align-items: center;
  justify-content: flex-end; }

.dscp-nav ul {
  display: flex;
  list-style: none; }

.dscp-nav li {
  display: flex;
  align-items: center;
  justify-content: center; }
  .dscp-nav li:last-child a {
    border-right: 0; }

.dscp-nav a {
  display: block;
  transition: 0.3s;
  border-right: 2px solid #129dc0;
  padding: 10px 20px;
  text-align: center;
  text-decoration: none;
  color: #8ba0b0;
  font-family: "CeraPRO-Bold", sans-serif;
  font-size: 16px; }
  .dscp-nav a:hover, .dscp-nav a:focus {
    transition: 0.3s;
    color: #174060; }

.dscp-nav a.current {
  color: #174060; }

.dscp-fr-button {
  display: inline-block;
  position: relative;
  transition: 0.3s;
  padding: 20px;
  text-transform: uppercase;
  text-decoration: none;
  color: #6b8599;
  font-family: "CeraPRO-Bold", sans-serif;
  font-size: 16px; }
  .dscp-fr-button::after {
    display: block;
    position: absolute;
    top: 20px;
    right: 0;
    border-top: 8px solid transparent;
    border-bottom: 8px solid transparent;
    border-left: 12px solid #87d3e1;
    width: 16px;
    height: 16px;
    content: ''; }
  .dscp-fr-button:hover, .dscp-fr-button:focus {
    transition: 0.3s;
    color: #174060; }

.menu-button {
  display: none;
  width: 60px;
  height: 60px; }

@media (max-width: 1023px) {
  .dscp-header-center {
    padding-bottom: 0px; } }

@media (max-width: 1023px) and (min-width: 480px) {
  .dscp-header {
    height: 100px; }
    .dscp-header-logo {
      width: 140px; } }

@media (max-width: 1023px) {
  .menu-button {
    display: block;
    margin: 0;
    outline: 0;
    border: 0;
    background: transparent;
    padding: 5px; }
    .menu-button svg {
      display: block;
      width: 50px;
      height: 50px; }
    .menu-button .top-bar-teal,
    .menu-button .bottom-bar-teal,
    .menu-button .middle-bar-teal,
    .menu-button .middle-bar-teal-2 {
      transition: 0.5s;
      transform-origin: center; }
    .menu-button .top-bar-teal {
      transform: rotate(0deg) translate(0, 0); }
    .menu-button .middle-bar-teal {
      transform: rotate(0deg) translate(0, 0); }
    .menu-button .bottom-bar-teal {
      transform: rotate(0deg) translate(0, 0); }
    .menu-button.open .top-bar-teal,
    .menu-button.open .bottom-bar-teal {
      fill: transparent; }
    .menu-button.open .middle-bar-teal {
      transform: rotate(45deg); }
    .menu-button.open .middle-bar-teal-2 {
      transform: rotate(-45deg); }
    .menu-button.open .middle-bar-pink-2 {
      transform: rotate(-45deg) translate(2px, 0); }
    .menu-button.open .middle-bar-black-1 {
      transform: rotate(45deg) translate(1px, -4px); }
    .menu-button.open .middle-bar-black-2 {
      transform: rotate(-45deg) translate(2px, 1px); }
  .dscp-header {
    position: relative; }
    .dscp-header-mobile-controls {
      display: flex; }
  .dscp-nav {
    display: none;
    position: absolute;
    top: 100px;
    right: 0;
    left: 0;
    background: rgba(236, 248, 250, 0.95);
    width: 100%;
    z-index: 100; }
    .dscp-nav.open {
      display: block; }
    .dscp-nav ul {
      flex-wrap: wrap;
      margin: 0;
      padding: 20px; }
    .dscp-nav li {
      width: 100%; }
    .dscp-nav a {
      border-right: 0; } }

@media (max-width: 479px) {
  .dscp-header {
    position: relative;
    height: 90px; }
    .dscp-header-logo {
      width: 120px; }
  .dscp-nav {
    top: 90px; } }

.site-gate h2 {
  font-size: 20px;
  color: #174060;
  margin: 20px 0;
  text-align: center; }

.site-gate p {
  font-family: "CeraPRO-Regular", sans-serif;
  color: #6b8599;
  text-align: center;
  font-size: 16px;
  line-height: 1.2; }

.site-gate-form {
  background: #fff;
  border-radius: 10px;
  padding: 20px;
  flex: 1 1 auto; }

.site-gate-field {
  padding: 10px 0; }

.site-gate-error {
  display: flex;
  justify-content: center; }
  .site-gate-error p {
    position: relative;
    padding-left: 30px;
    color: #ff1710;
    margin-top: 0; }
    .site-gate-error p:before {
      content: '!';
      position: absolute;
      display: block;
      width: 16px;
      height: 16px;
      font-size: 14px;
      left: 5px;
      background: #ff1710;
      border-radius: 50%;
      color: #fff;
      font-family: "CeraPRO-Bold", sans-serif; }

.button-question {
  background: #129dc0;
  height: 16px !important;
  width: 16px !important;
  font-size: 12px;
  display: inline-block;
  color: #fff;
  border: 0;
  outline: 0;
  font-family: "CeraPRO-Medium", sans-serif;
  border-radius: 50%;
  position: relative;
  vertical-align: super;
  margin: 0 5px; }
  .button-question .question {
    position: absolute;
    top: 2px;
    left: 5px; }

.open-din-info {
  transition: 0.3s;
  margin: 12px 0 0 0;
  outline: 0;
  border: 0;
  width: 100%;
  background: transparent;
  text-align: center;
  text-decoration: underline;
  color: #129dc0;
  font-family: "CeraPRO-Medium", sans-serif; }
  .open-din-info:hover, .open-din-info:focus {
    transition: 0.3s;
    color: #0f89a8; }

@media (min-width: 1023px) {
  .tab-label,
  .tab-input {
    display: none; }
  .form-section-wrapper {
    display: flex; }
  .form-section {
    display: flex;
    width: 50%; }
  .site-gate-wrapper {
    position: relative;
    display: flex; }
    .site-gate-wrapper-hcp {
      padding-left: 150px;
      padding-right: 10px; }
    .site-gate-wrapper-pat {
      padding-right: 150px;
      padding-left: 10px; }
  .site-gate-form-outer {
    flex-direction: column;
    display: flex; }
  .site-gate-img {
    max-width: 200px;
    height: auto;
    position: absolute;
    bottom: 0; }
    .site-gate-img-hcp {
      left: 0; }
    .site-gate-img-pat {
      right: 0; }
  .site-gate-form-hcp {
    padding-left: 60px; }
  .site-gate-form-pat {
    padding-right: 60px; }
  .form-img-p-hcp-fr {
    padding-left: 60px;
    font-size: 14px !important;
    text-align: left !important; }
  .form-img-p-pat-fr {
    padding-right: 50px;
    font-size: 14px !important;
    text-align: right !important; }
  .form-img-p-hcp {
    padding-left: 60px;
    text-align: left !important; }
  .form-img-p-pat {
    padding-right: 50px;
    text-align: right !important; } }

@media (max-width: 1023px) {
  .site-gate-img {
    display: none; }
  .tabs-form {
    position: relative;
    min-height: 550px;
    /* This part sucks */
    margin: 25px auto;
    max-width: 600px; }
  .tabs-form-fr {
    min-height: 600px; }
  .tab-form .tab-label {
    background: #eee;
    padding: 10px 10px 10px 20px;
    border: 1px solid #f2f2f2;
    margin-left: -1px;
    position: absolute;
    height: 41px;
    width: 50%;
    transition: 0.3s;
    cursor: pointer;
    display: flex;
    align-items: center; }
    .tab-form .tab-label-pat {
      right: 0;
      top: 0;
      border-top-right-radius: 10px; }
    .tab-form .tab-label-hcp {
      left: 1px;
      top: 0;
      border-top-left-radius: 10px; }
    .tab-form .tab-label:hover, .tab-form .tab-label:focus {
      color: #129dc0;
      transition: 0.3s; }
  .tab-form .tab-input {
    display: none; }
  .tab-content {
    position: absolute;
    top: 40px;
    left: 0;
    background: white;
    right: 0;
    bottom: 0;
    padding: 0;
    border: 1px solid #f2f2f2;
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px; }
  .tab-input:checked ~ label {
    background: white;
    border-bottom: 1px solid white;
    z-index: 2;
    color: #129dc0; }
  .tab-input:checked ~ .tab-label ~ .tab-content {
    z-index: 1; }
  .form-img-p {
    display: none; } }

@media (max-width: 479px) {
  .tab-content {
    top: 60px; }
  .tab-form .tab-label {
    height: 61px; }
  .site-gate h2 {
    margin: 10px 0; }
  .site-gate-form {
    padding: 10px 15px; } }

.patient-btn {
  max-width: 500px;
  width: 100%;
  display: block;
  min-height: 80px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0; }

.dscp-legal h1,
.dscp-legal h2,
.dscp-legal h3 {
  margin: 48px 0 6px 0;
  color: #174060; }

.dscp-legal p {
  margin: 12px 0;
  line-height: 1.5; }

.dscp-legal strong {
  color: #174060; }

.dscp-footer {
  background: #174060;
  color: #fff; }
  .dscp-footer-upper {
    display: flex;
    align-items: center;
    justify-content: space-between; }
  .dscp-footer-legal-wrapper {
    display: flex;
    justify-content: center; }
  .dscp-footer-legal {
    display: flex;
    align-items: stretch;
    margin: 0;
    padding: 10px 0;
    list-style: none;
    text-align: center;
    font-size: 16px; }
    .dscp-footer-legal li {
      display: flex;
      align-items: center;
      justify-content: center;
      border-right: 1px solid #fff; }
      .dscp-footer-legal li:first-child a {
        padding-left: 10px; }
      .dscp-footer-legal li:last-child {
        border-right: 0; }
    .dscp-footer-legal a {
      display: block;
      transform: 0.3s;
      padding: 2px 10px;
      text-decoration: none;
      color: #fff;
      font-family: "CeraPRO-Regular", sans-serif; }
      .dscp-footer-legal a:hover, .dscp-footer-legal a:focus {
        transform: 0.3s;
        color: #b4e1e8; }
  .dscp-footer-logos {
    display: flex;
    align-items: center;
    padding: 10px 0; }
    .dscp-footer-logos img {
      display: block;
      margin: 10px;
      width: auto;
      height: 20px; }
    .dscp-footer-logos .logo-xsm {
      height: 15px; }
    .dscp-footer-logos .logo-sm {
      height: 20px; }
    .dscp-footer-logos .logo-md {
      height: 35px; }
    .dscp-footer-logos .logo-lg {
      height: 65px; }
  .dscp-footer-info {
    margin: 0 auto;
    max-width: 600px;
    text-align: left; }
    .dscp-footer-info p {
      line-height: 1.4;
      color: #fff;
      font-size: 12px; }
  .dscp-footer-patient .dscp-footer-logos {
    width: 400px; }
  .dscp-footer-patient .dscp-footer-legal-wrapper {
    width: 380px; }
  .dscp-footer-patient .dscp-footer-info {
    width: calc(100% - 780px);
    padding: 0 10px 0 20px; }
  .dscp-footer-hcp .dscp-footer-logos {
    width: 400px; }
  .dscp-footer-hcp .dscp-footer-legal-wrapper {
    width: 270px; }
  .dscp-footer-hcp .dscp-footer-info {
    width: calc(100% - 670px);
    padding: 0 10px 0 20px; }

@media (max-width: 1023px) {
  .dscp-footer-upper {
    flex-wrap: wrap; }
  .dscp-footer-legal-wrapper {
    display: flex;
    justify-content: center;
    width: 100%; }
  .dscp-footer-logos {
    flex-wrap: wrap;
    justify-content: center;
    width: 100%; }
  .dscp-footer-patient .dscp-footer-logos {
    width: 100%; }
  .dscp-footer-patient .dscp-footer-legal-wrapper {
    width: 100%; }
  .dscp-footer-patient .dscp-footer-info {
    width: 100%;
    padding: 10px 0; }
  .dscp-footer-hcp .dscp-footer-logos {
    width: 100%; }
  .dscp-footer-hcp .dscp-footer-legal-wrapper {
    width: 100%; }
  .dscp-footer-hcp .dscp-footer-info {
    width: 100%;
    padding: 10px 0; } }
