.patient-btn {
	max-width: 500px;
	width: 100%;
	display: block;
	min-height: 80px;
	text-align: center;
	display: flex;
	align-items: center;
	justify-content: center;
	margin: 0;
}
